import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHeadersFormUrlEncoded } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICredenciaisUsuario } from '../../models';
import { RsaService } from '../rsa/rsa.service';
import { IAplicacaoAPI } from './../../models/usuario.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    URL_BASE = environment.URL_LOGIN + environment.PATH_LOGIN;

    constructor(private http: HttpClient, private rsa: RsaService) {}

    async redefinirSenha(credenciais: ICredenciaisUsuario, token: string, recaptcha: string) {
        const { nonceToken, senhaEncriptada } = await this.rsa.gerarNonceTokenESenha(credenciais);

        return lastValueFrom(
            this.http.put(
                `${this.URL_BASE}/login-externo/api/v1/usuario/senha/${environment.APP}`,
                {
                    nonceToken: nonceToken,
                    senhaEncriptada: senhaEncriptada,
                    token: token,
                    recaptcha
                }
            )
        );
    }

    async recuperarSenha(email: string) {
        const params = new HttpParams().set('email', email);
        return lastValueFrom(
            this.http.post(
                `${this.URL_BASE}/api/v1/usuario/${email}/senha/${environment.APP}`,
                params.toString(),
                {
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }

    async listarAplicacoes(): Promise<IAplicacaoAPI[]> {
        return await lastValueFrom(
            this.http.get<IAplicacaoAPI[]>(`${this.URL_BASE}/api/v1/aplicacoes`)
        );
    }
}
