import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDataTableResponse, IFileData } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAddressComponent, IFornecedor, ISocios } from '../../models';
import { IBanco } from '../../models/banco.model';

@Injectable({
    providedIn: 'root'
})
export class FornecedorService {
    URL_BASE = environment.URL_BASE + environment.PATH;
    _fornecedor!: IFornecedor | null;

    constructor(private _http: HttpClient) {}

    setFornecedor(data: IFornecedor | null) {
        this._fornecedor = data;
    }

    getFornecedor(): IFornecedor {
        return this._fornecedor!;
    }

    getFornecedorData(): Promise<IFornecedor> {
        return lastValueFrom(
            this._http.get<IFornecedor>(`${this.URL_BASE}/api/v1/fornecedorexterno`)
        );
    }

    getSelectBancoEndpoint(): Promise<IDataTableResponse<IBanco>> {
        return lastValueFrom(
            this._http.get<IDataTableResponse<IBanco>>(
                `${this.URL_BASE}/api/v1/banco?per_page=200&page=0`
            )
        );
    }

    getFiles(fornecedorId: string): Promise<IFileData[]> {
        return lastValueFrom(
            this._http.get<IFileData[]>(
                `${this.URL_BASE}/api/v1/filemanager/blob-info/list/${fornecedorId}`
            )
        );
    }

    getAddress(): Promise<IDataTableResponse<IAddressComponent>> {
        return lastValueFrom(
            this._http.get<IDataTableResponse<IAddressComponent>>(
                `${this.URL_BASE}/api/v1/fornecedorexterno/endereco`
            )
        );
    }

    postAddress(address: IAddressComponent) {
        return lastValueFrom(
            this._http.post(`${this.URL_BASE}/api/v1/fornecedorexterno/endereco`, address)
        );
    }

    putAddress(address: IAddressComponent) {
        return lastValueFrom(
            this._http.put(`${this.URL_BASE}/api/v1/fornecedorexterno/endereco`, address)
        );
    }

    getSocios(): Promise<IDataTableResponse<ISocios>> {
        return lastValueFrom(
            this._http.get<IDataTableResponse<ISocios>>(
                `${this.URL_BASE}/api/v1/fornecedorexterno/representanteLegal`
            )
        );
    }

    postSocios(socios: ISocios) {
        return lastValueFrom(
            this._http.post(`${this.URL_BASE}/api/v1/fornecedorexterno/representanteLegal`, socios)
        );
    }

    putSocios(socios: ISocios) {
        return lastValueFrom(
            this._http.put(`${this.URL_BASE}/api/v1/fornecedorexterno/representanteLegal`, socios)
        );
    }

    putFornecedor(body: IFornecedor) {
        return lastValueFrom(this._http.put(`${this.URL_BASE}/api/v1/fornecedorexterno`, body));
    }
}
