import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {
    AUTH_INTERCEPTOR_CONFIG,
    LOCAL_STORAGE_CONFIG_TOKEN,
    MenuLateralModule,
    NavBarModule,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PermissoesFactory } from './core/guards/permissoes-factory';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        NavBarModule,
        MenuLateralModule
    ],
    providers: [
        PermissoesFactory,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: LOCAL_STORAGE_CONFIG_TOKEN,
            useValue: environment
        },
        {
            provide: PERMISSOES_SERVICE_TOKEN,
            useClass: PermissoesService
        },
        {
            provide: AUTH_INTERCEPTOR_CONFIG,
            useValue: AUTH_INTERCEPTOR_CONFIG
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
