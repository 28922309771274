import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';
import { rotaPublicaGuard } from './core/guards/rota-publica.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./pages/login/login-externo.module').then((m) => m.LoginExternoModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'redefinir-senha/:token',
        loadChildren: () =>
            import('./pages/redefinir-senha/redefinir-senha.module').then(
                (m) => m.RedefinirSenhaModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'recuperar-senha',
        loadChildren: () =>
            import('./pages/recuperar-senha/recuperar-senha.module').then(
                (m) => m.RecuperarSenhaModule
            ),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'check-mfa',
        loadChildren: () =>
            import('./pages/check-mfa/check-mfa.module').then((m) => m.CheckMfaModule),
        canActivate: [rotaPublicaGuard]
    },
    {
        path: 'fornecedor',
        loadChildren: () =>
            import('./pages/fornecedor/fornecedor.module').then((m) => m.FornecedorModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.fornecedor.roles }
    },
    {
        path: 'dados-bancarios',
        loadChildren: () =>
            import('./pages/dados-bancarios/dados-bancarios.module').then(
                (m) => m.DadosBancariosModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.fornecedor.roles }
    },
    {
        path: 'questionario-reputacional',
        loadChildren: () =>
            import('./pages/questionario/questionario.module').then((m) => m.QuestionarioModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itemMenu.fornecedor.roles }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
